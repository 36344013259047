var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _c("b-col", { attrs: { cols: "auto" } }, [
            _vm.$route.query.r
              ? _c("h3", [_vm._v("Set Password")])
              : _c("h3", [_vm._v("Password Reset")]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _vm.status !== "DONE"
            ? _c(
                "b-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        variant: "danger",
                        show: _vm.status === "ERROR",
                      },
                    },
                    [
                      _vm._v(
                        " An Error Occurred. Please check you copied the link correctly. If the problem persists, contact support. "
                      ),
                    ]
                  ),
                  _c(
                    "b-form",
                    { on: { submit: _vm.resetPassword } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "New Password" } },
                        [
                          _c("b-input", {
                            attrs: {
                              name: "password",
                              type: "password",
                              state: _vm.errors.password,
                            },
                            model: {
                              value: _vm.form.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "password", $$v)
                              },
                              expression: "form.password",
                            },
                          }),
                          _c("b-form-text", [
                            _vm._v(
                              " Password must contain at least one uppercase, one lowercase, and one numerical character "
                            ),
                          ]),
                          _c("b-form-invalid-feedback", [
                            _vm._v("Password does not meet requirements"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { label: "Confirm Password" } },
                        [
                          _c("b-input", {
                            attrs: {
                              name: "passwordConf",
                              type: "password",
                              state: _vm.errors.passwordConf,
                            },
                            model: {
                              value: _vm.form.passwordConf,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "passwordConf", $$v)
                              },
                              expression: "form.passwordConf",
                            },
                          }),
                          _c("b-form-invalid-feedback", [
                            _vm._v("Passwords do not match"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            type: "submit",
                            varian: "primary",
                            disabled: _vm.status === "ERROR",
                          },
                        },
                        [
                          _vm.status === "PENDING"
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _c("span", [_vm._v("Submit")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.status === "DONE"
            ? _c(
                "b-col",
                { attrs: { cols: "auto" } },
                [
                  _vm._v(" Done! Your password has been changed. "),
                  _c("b-link", { attrs: { to: "/" } }, [_vm._v("Click here")]),
                  _vm._v(" to login. "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }